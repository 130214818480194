import { useState, useEffect } from "react";
import { Container, Tab, Tabs, OverlayTrigger, Popover } from "react-bootstrap";
import { BsQuestionCircle } from "react-icons/bs"
import TabellaReferenda from "./TabellaReferenda";
import GestisciReferendum from "./GestisciReferendum";
import ImpostazioniReferenda from "./ImpostazioniReferenda";
import ReferendaService from "../../../services/ReferendaService";
import ModuloNonAttivo from "../ModuloNonAttivo";
import { useNavigate, useLocation } from "react-router-dom";
import PaginaNonTrovata from "../../Utente/Error/PaginaNonTrovata";

export default function Referenda(props) {
    const navigate = useNavigate();
    const location = useLocation();

    let [ricaricaReferendum, setRicaricaReferendum] = useState(true)

    // Fetch referenda
    let [referenda, setReferenda] = useState([])

    let [statoFilter, setStatoFilter] = useState("")
    let [tipoFilter, setTipoFilter] = useState("")

    // Stato che passa l'id del referendum da mostrare per i componenti 
    // - GestisciReferendum
    let [refId, setRefId] = useState("")

    // Stato che decide come deve essere gestito il referendum
    let [gestisci, setGestisci] = useState("")

    useEffect(() => {
        if (ricaricaReferendum && props.comune.comId) {
            ReferendaService.findByComune(props.comune.comId)
                .then(res => {
                    setReferenda(res.data)
                })
                .catch(() => {
                    setReferenda([])
                })
                .finally(() => {
                    setRicaricaReferendum(false)
                })
        }
    }, [ricaricaReferendum, props.comune.comId]);

    const toggleTab = (ev) => {
        if (ev.target.dataset.rrUiEventKey === "impostazioni") {
            props.setTabAttivaReferendum("impostazioni")
            navigate("/admin/referendum#impostazioni")
        }
        if (ev.target.dataset.rrUiEventKey === "referenda") {
            props.setTabAttivaReferendum("referenda")
            navigate("/admin/referendum#tutti-i-referendum")
        }
    }

    useEffect(() => {
        if (location.hash === "#tutti-i-referendum") props.setTabAttivaReferendum("referenda")
        if (location.hash === "#impostazioni") props.setTabAttivaReferendum("impostazioni")
    }, [props, location])

    if (props.partecipante.parId && props.partecipante.ruolo.ruoFlAmministratore) {

    return (
        <Container fluid="md">
            <div className="d-flex justify-content-between">
                <h1 className="title">Referendum</h1>
                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloReferendumAttivo &&
                    <OverlayTrigger placement="left"  trigger="click" rootClose
                        overlay={
                            <Popover id="popover-contained">
                                <Popover.Header as="h3">Aiuto:</Popover.Header>
                                <Popover.Body>
                                    Ecco tutti i referendum inviati dai partecipanti.
                                    <br />
                                    <br />
                                    Possibili <strong>stati</strong>:
                                    <ul>
                                        <li><strong>In validazione</strong>: referendum non visibile ai partecipanti</li>
                                        <li><strong>Rifiutato</strong>: referendum di cui è visualizzabile solo il titolo</li>
                                        <li><strong>In corso</strong>: i partecipanti possono apporre la propria firma</li>
                                        <li><strong>In attesa di ammissione</strong>: il referendum necessita di una approvazione per superare la soglia corrente</li>
                                        <li><strong>Approvato</strong>: referendum in fase di voto</li>
                                        <li><strong>Chiuso</strong>: referendum per cui è stato pubblicato l'esito della consultazione dopo la fase di voto</li>
                                    </ul>
                                    <br />
                                    Possibili <strong>azioni</strong>:
                                    <ul>
                                        <li><strong>Controlla</strong>: per rifiutare o pubblicare il referendum</li>
                                        <li><strong>Rispondi</strong>: per inserire l'esito della consultazione al termine della fase di voto</li>
                                        <li><strong>Registra firme</strong>: per registrare firme raccolte al di fuori della piattaforma</li>
                                    </ul>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <div className="my-auto" style={{cursor:"pointer"}} >
                            <BsQuestionCircle size="2em" className="my-auto" />
                        </div>
                    </OverlayTrigger>
                }
            </div>
            {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloReferendumAttivo ? (
                <Tabs activeKey={props.tabAttivaReferendum} id="uncontrolled-tab-example" className="mb-3"
                    onClick={toggleTab}>
                    <Tab eventKey="referenda" title="Referendum">
                        {props.mostraTabella ?
                            <TabellaReferenda
                                comune={props.comune}
                                referenda={referenda}
                                setRefId={setRefId}
                                setMostraTabella={props.setMostraTabella}
                                setGestisci={setGestisci}
                                statoFilter={statoFilter}
                                setStatoFilter={setStatoFilter}
                                tipoFilter={tipoFilter}
                                setTipoFilter={setTipoFilter}
                            /> :
                            <GestisciReferendum
                                comune={props.comune}
                                referenda={referenda}
                                refId={refId}
                                setMostraTabella={props.setMostraTabella}
                                gestisci={gestisci}
                                setGestisci={setGestisci}
                                setRicaricaReferendum={setRicaricaReferendum}
                            />
                        }
                    </Tab>
                    <Tab eventKey="impostazioni" title="Impostazioni">
                        <ImpostazioniReferenda
                            referenda={referenda}
                            setRicaricaReferendum={setRicaricaReferendum}
                            comune={props.comune}
                            setRicaricaComune={props.setRicaricaComune}
                        />
                    </Tab>
                </Tabs>
            ) : (
                <ModuloNonAttivo moduleName="Referendum" />
            )}
        </Container>
    )
} else {
    return (
           <PaginaNonTrovata/>
    )
}

}