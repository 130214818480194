import { Container, Tabs, Tab } from "react-bootstrap";
import ImpostazioniComune from "./ImpostazioniComune";
import AttivazioneModuli from "./AttivazioneModuli";
import ImpostazioniModuli from "./ImpostazioniModuli";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import PaginaNonTrovata from "../../Utente/Error/PaginaNonTrovata";
import { RiVipCrownFill } from "react-icons/ri";

export default function ImpostazioniGenerali(props) {
    const navigate = useNavigate();
    const location = useLocation();

    const toggleTab = (ev) => {
        if (ev.target.dataset.rrUiEventKey === "impostazioni-comune") {
            props.setTabAttivaImpostazioni("impostazioni-comune")
            navigate("/admin/impostazioni-generali#impostazioni-comune")
        }
        if (ev.target.dataset.rrUiEventKey === "attivazione-moduli") {
            props.setTabAttivaImpostazioni("attivazione-moduli")
            navigate("/admin/impostazioni-generali#attivazione-moduli")
        }
        if (ev.target.dataset.rrUiEventKey === "impostazioni-moduli") {
            props.setTabAttivaImpostazioni("impostazioni-moduli")
            navigate("/admin/impostazioni-generali#impostazioni-moduli")
        }
    }

    useEffect(() => {
        if (location.hash === "#impostazioni-comune") props.setTabAttivaImpostazioni("impostazioni-comune")
        if (location.hash === "#attivazione-moduli") props.setTabAttivaImpostazioni("attivazione-moduli")
        if (location.hash === "#impostazioni-moduli") props.setTabAttivaImpostazioni("impostazioni-moduli")
    }, [props, location])


    if (props.partecipante.parId && props.partecipante.ruolo.ruoFlAmministratore) {

        return (
            <Container fluid="md">
                <div className="d-flex justify-content-between">
                    <h1 className="title">Impostazioni generali</h1>
                </div>

                <Tabs activeKey={props.tabAttivaImpostazioni} id="uncontrolled-tab-example" className="mb-3"
                    onClick={toggleTab}>
                    <Tab eventKey="impostazioni-comune" title="Impostazioni comune">
                        <ImpostazioniComune
                            comune={props.comune}
                            setRicaricaComune={props.setRicaricaComune}
                        />
                    </Tab>
                    <Tab eventKey="impostazioni-moduli" title="Impostazioni moduli">
                    <ImpostazioniModuli
                                comune={props.comune}
                                setRicaricaComune={props.setRicaricaComune}
                            />
                    </Tab>
                    {props.partecipante.ruolo.ruoFlSuperAdmin &&

                        <Tab eventKey="attivazione-moduli" title={<><RiVipCrownFill className="mb-1" /> Attivazione moduli</>}>
                            <AttivazioneModuli
                                comune={props.comune}
                                setRicaricaComune={props.setRicaricaComune}
                            />
                        </Tab>
                    }

                </Tabs>
            </Container >
        )
    } else {
        return (<PaginaNonTrovata />
        )
    }

}