import { statiBilancio, statiPetizione, statiReferendum } from "../../Utilities/statiElementEnum";
import { Card, Button, Col, Row, Container } from "react-bootstrap";
import { useState, useEffect } from "react";
import { Link as ScrollLink, } from "react-scroll";

import PetizioniService from "../../../services/PetizioniService";
import ReferendaService from "../../../services/ReferendaService";
import BilancioPartecipativoService from "../../../services/BilancioPartecipativoService";
import CartaElementiHomepage from "./CartaElementiHomepage";

import { useNavigate } from "react-router-dom";
import { getStatusOrderPetizione, getStatusOrderReferendum, getStatusOrderBilancio } from "../../Utilities/getStatusOrder";
import { getStatusPetizione, getStatusReferendum, getStatusBilancio } from '../../Utilities/getStatus';
import { petizioni, referendum, bilancio_partecipativo, segnalazioni } from '../../../utilities/icone'
import homepageImageDefault from '../../../immagini/homepage/homepage_image.png'
import { useLocation } from 'react-router-dom';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}


export default function Homepage(props) {
    let query = useQuery();

    //fetch dei dati
    let [listaPetizioni, setListaPetizioni] = useState([])
    const [loadingPet, setLoadingPet] = useState(true)
    let [listaReferendum, setListaReferenum] = useState([])
    const [loadingRef, setLoadingRef] = useState(true)
    let [listaBilanci, setListaBilanci] = useState([])
    const [loadingBil, setLoadingBil] = useState(true)

    const navigate = useNavigate();

    const getBannerUrl = () => {
        if (props.comune.comBannerFile)
            return "data:image/png;base64," + props.comune.comBannerFile
        else
            return homepageImageDefault
    }

    useEffect(()=>{
        const cfbase64 = query.get('code')
        const token= query.get('token')
        if(cfbase64 && token){
            sessionStorage.setItem('partecipante', JSON.stringify(cfbase64))
            sessionStorage.setItem('token', btoa(JSON.stringify(token)))
            props.setCaricaPartecipante(true)
        }
    // eslint-disable-next-line
    }, [props.setCaricaPartecipante]) 
    
    useEffect(() => {
        if (props.comune.comId) {
            PetizioniService.findByComune(props.comune.comId)
                .then(res => { setListaPetizioni(res.data) })
                .catch((err) => { setListaPetizioni([]) })
                .finally(() => { setLoadingPet(false) })
            ReferendaService.findByComune(props.comune.comId)
                .then(res => { setListaReferenum(res.data) })
                .catch(() => { setListaReferenum([]) })
                .finally(() => { setLoadingRef(false) })
            BilancioPartecipativoService.getAllByComune(props.comune.comId)
                .then(res => { setListaBilanci(res.data) })
                .catch((err) => { setListaBilanci([]) })
                .finally(() => { setLoadingBil(false) })
        }
    }, [props.comune, navigate]);

    return (
        <>
            <Card className="mt-md-5 mx-md-5">
                <Row className="w-100 p-0 m-0">
                    <Col sm={6} className="d-flex align-items-center">
                        <Card.Body className="text-center p-2">
                            <div className="my-auto">
                                <Card.Title className=" mb-4">
                                    <h1 className="title">{props.comune.comNome} Partecipa!</h1>
                                </Card.Title>
                                <Card.Text>
                                    {props.comune.comSottotitolo ? (
                                        <strong>{props.comune.comSottotitolo}</strong>
                                    ) : (
                                        <strong>Scopri i processi di democrazia partecipativa attivi, dai un contributo alla tua città!</strong>
                                    )}
                                    <br />
                                    <br />
                                    {props.comune.comDescrizione ? (
                                        <span className="italic">{props.comune.comDescrizione}</span>
                                    ) : (
                                        <span className="italic">
                                            Questa è la piattaforma del comune per la partecipazione dei cittadini alla vita della città. Rende possibile pubblicare e votare petizioni, referendum e proposte.
                                        </span>
                                    )}
                                </Card.Text>
                            </div>
                        </Card.Body>
                    </Col>
                    <Col sm={6} className="d-none d-sm-flex align-items-center p-0">
                        <Card.Img src={getBannerUrl()} style={{ maxHeight: "50vh" }} />
                    </Col>
                </Row>
            </Card>

            {
                props.comune && props.comune.impostazioniComune &&
                (props.comune.impostazioniComune.imcModuloPetizioniAttivo ||
                    props.comune.impostazioniComune.imcModuloReferendumAttivo ||
                    props.comune.impostazioniComune.imcModuloBilanciAttivo ||
                    props.comune.impostazioniComune.imcModuloSegnalazioniAttivo) &&
                <Container className="text-center mt-4 mx-4 mx-md-5" fluid="true" >
                    <Card border="secondary" className="mt-2">
                        <Card.Body className="p-0">
                            <Row className="d-flex justify-content-between">
                                {props.comune.impostazioniComune.imcModuloPetizioniAttivo &&
                                    <Col xs={6} md={3} >
                                        <ScrollLink to="petizioni" offset={-70} spy={true} duration={70000} className="w-100">
                                            <Button variant="outline-light p-3 w-100 h-100" style={{ color: "black" }} >
                                                {petizioni}{' '} Petizioni
                                            </Button>
                                        </ScrollLink>
                                    </Col>
                                }
                                {props.comune.impostazioniComune.imcModuloReferendumAttivo &&
                                    <Col xs={6} md={3} >
                                        <ScrollLink to="referendum" offset={-70} spy={true} duration={70000} className="w-100">
                                            <Button variant="outline-light p-3 w-100 h-100" style={{ color: "black" }} >
                                                {referendum}{' '} Referendum
                                            </Button>
                                        </ScrollLink>
                                    </Col>
                                }
                                {props.comune.impostazioniComune.imcModuloSegnalazioniAttivo &&
                                    <Col xs={6} md={3} >
                                        <ScrollLink to="segnalazioni" offset={-70} spy={true} duration={70000} className="w-100">
                                            <Button variant="outline-light p-3 w-100 h-100" style={{ color: "black" }}>
                                                {segnalazioni} {' '} Segnalazioni
                                            </Button>
                                        </ScrollLink>
                                    </Col >
                                }
                                {props.comune.impostazioniComune.imcModuloBilanciAttivo &&
                                    <Col xs={6} md={3} >
                                        <ScrollLink to="bilancio" offset={-70} spy={true} duration={70000} className="w-100">
                                            <Button variant="outline-light p-3 w-100 h-100" style={{ color: "black" }}>
                                                {bilancio_partecipativo} {' '} {props.comune.impostazioniComune.imcLabelModuloBilanci}
                                            </Button>
                                        </ScrollLink>
                                    </Col>
                                }
                            </Row>
                        </Card.Body>
                    </Card>
                </Container>
            }

            {/* LISTE DEGLI ELEMENTI */}
            <Card className="mt-4  mb-5 mx-4 mx-md-5">
                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloPetizioniAttivo &&
                    <CartaElementiHomepage
                        titolo="Petizioni"
                        identificativo="petizioni"
                        testoLink="Vedi di più"
                        path="/esplora-petizioni"
                        partecipante={props.partecipante}
                        listaElementi={listaPetizioni && listaPetizioni.length > 0 && listaPetizioni
                            .map(el => { return { ...el, petStato: getStatusPetizione(el, props.comune) } })
                            .filter(el => (
                                el.petStato !== statiPetizione.InValidazione &&
                                el.petStato !== statiPetizione.Rifiutata
                            ))
                            .sort(function (a, b) {
                                return getStatusOrderPetizione(a.petStato) - getStatusOrderPetizione(b.petStato)
                            })
                        }
                        comune={props.comune}
                        mode="petizione"
                        loading={loadingPet}
                    />
                }

                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloReferendumAttivo &&
                    <CartaElementiHomepage
                        titolo="Referendum"
                        identificativo="referendum"
                        path="/esplora-referendum"
                        testoLink="Vedi di più"
                        loading={loadingRef}
                        mode="referendum"
                        partecipante={props.partecipante}
                        listaElementi={listaReferendum && listaReferendum.length > 0 &&
                            listaReferendum
                                .map(ref => { return { ...ref, refStato: getStatusReferendum(ref, props.comune) } })
                                .filter(el => (
                                    el.refStato !== statiReferendum.InValidazione &&
                                    el.refStato !== statiReferendum.Rifiutato
                                ))
                                .sort(function (a, b) {
                                    return getStatusOrderReferendum(a.refStato) - getStatusOrderReferendum(b.refStato)
                                })
                        }
                        comune={props.comune}
                    />
                }

                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloSegnalazioniAttivo &&
                    <CartaElementiHomepage
                        titolo="Segnalazioni"
                        identificativo="segnalazioni"
                        path="/esplora-segnalazioni"
                        testoLink="Vedi di più"
                        loading={false}
                        mode="segnalazione"
                        partecipante={props.partecipante}
                        listaElementi={[]}
                        comune={props.comune}
                    />
                }

                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloBilanciAttivo &&
                    <CartaElementiHomepage
                        titolo={props.comune.impostazioniComune.imcLabelModuloBilanci}
                        identificativo="bilancio"
                        path="/esplora-bilanci-partecipativi"
                        testoLink="Vedi di più"
                        loading={loadingBil}
                        mode="bilancio"
                        partecipante={props.partecipante}
                        listaElementi={listaBilanci
                            .map(bil => { return { ...bil, bilStato: getStatusBilancio(bil, props.comune) } })
                            .filter(el => el.bilStato !== statiBilancio.NonPubblicato)
                            .sort(function (a, b) {
                                return getStatusOrderBilancio(b.bilEdizione) - getStatusOrderBilancio(a.bilEdizione)
                            })}
                        comune={props.comune}
                    />
                }
            </Card>
        </>
    )
}