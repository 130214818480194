import { useState, useEffect} from "react";
import { Form, Row, Col, Card, InputGroup, Container, Button } from "react-bootstrap";
import Categorie from "../Utilities/Categorie";
import ImpostazioniComuneService from "../../../services/ImpostazioniComuneService";
import ModalConferma from "../../Utilities/ModalConferma";
import MappaEIndirizzi from "../../Utilities/MappaEIndirizzi";


export default function ImpostazioniSegnalazioni(props) {
  let [categorie, setCategorie] = useState([])
  let [scadenza, setScadenza] = useState(0)

  // Stati per le finestre modali
  const [mostraModaleConferma, setMostraModaleConferma] = useState(false)
  const [mostraModaleSuccesso, setMostraModaleSuccesso] = useState(false)
  const [mostraModaleErrore, setMostraModaleErrore] = useState(false)

  //gestione mappa
  const [indirizzo, setIndirizzo] = useState("")
  const [posizione, setPosizione] = useState([0,0])

  const salvaImpostazioni = () => {
    let impostazioni = {
      ...props.comune.impostazioniComune,
      imcCategorieSegnalazioni: { categorie_segnalazioni: [...categorie] },
      imcScadenzaSegnalazioni: scadenza,
      imcCentroMappa : {"centro_mappa":posizione},
      comune: props.comune
    }

    console.log("invio", impostazioni)
    ImpostazioniComuneService.aggiornaImpostazioni(impostazioni)
      .then(res => {
        if (res.status === 200) {
          setMostraModaleConferma(false)
          setMostraModaleSuccesso(true)
          props.setRicaricaComune(true)
        }
        else if (res.status === 500) {
          setMostraModaleConferma(false)
          setMostraModaleErrore(true)
        }
      }).catch(err => {
        setMostraModaleErrore(true)
      })
  }

  useEffect(() => {
    setCategorie((props.comune.impostazioniComune && props.comune.impostazioniComune.imcCategorieSegnalazioni && props.comune.impostazioniComune.imcCategorieSegnalazioni.categorie_segnalazioni) || [])
    setScadenza((props.comune.impostazioniComune && props.comune.impostazioniComune.imcScadenzaSegnalazioni) || 30)
    setPosizione((props.comune.impostazioniComune && props.comune.impostazioniComune.imcCentroMappa.centro_mappa))
    setIndirizzo((props.comune.comNome))
  }, [props.petizioni, props.comune])


  return (
    <>
      <Form>
        <Row className="d-flex">
        <Col xs={12}>
            <Form.Group as={Row} className="mb-4" controlId="formscadenza">
              <Form.Label column sm="6">
                Giorni per rispondere alla segnalazione
              </Form.Label>
              <Col sm="6">
                <InputGroup className="mb-3">
                  <Form.Control
                    value={scadenza}
                    type="number"
                    className="text-center"
                    onChange={(ev) => { setScadenza(ev.target.value) }}
                  />
                </InputGroup>
              </Col>
            </Form.Group>
          </Col>
          <Col xs={12} className="mb-3">
          <h6 className="title">Imposta il centro della mappa per le segnalazioni</h6>
            <MappaEIndirizzi  
            posizione={posizione}
            setPosizione={setPosizione}
            indirizzo={indirizzo} 
            setIndirizzo={setIndirizzo}
            />
          </Col>

          <Col xs={12}>
            <Card className="h-100 p-3 pt-0">
              <Form.Group as={Col} className="mb-4 h-100">
                <Form.Label column sm="6" className="pb-0">
                  <p className="mb-2">Categorie segnalazioni</p>
                </Form.Label>
                <Categorie categorie={categorie} setCategorie={setCategorie} />
              </Form.Group>
            </Card>
          </Col>
        </Row >
      </Form >
      <Container className="d-flex justify-content-end w-90 p-0 pt-5">
        <Button onClick={() => setMostraModaleConferma(true)}>Aggiorna</Button>
      </Container>

      <ModalConferma
        mostra={mostraModaleConferma}
        setMostra={setMostraModaleConferma}
        mode="continua"
        titolo="Salvataggio impostazioni"
        messaggio="Confermi il salvataggio delle impostazioni?"
        bodyAlign="text-center"
        btnConferma="Si, salva"
        btnAnnulla="No, annulla"
        azioneAnnulla={() => { setMostraModaleConferma(false) }}
        azioneConferma={salvaImpostazioni}
      />

      <ModalConferma
        mostra={mostraModaleSuccesso}
        setMostra={setMostraModaleSuccesso}
        mode="success"
        titolo="Salvataggio impostazioni"
        messaggio="Impostazioni salvate con successo"
        bodyAlign="text-center"
        btnConferma="Chiudi"
        azioneConferma={() => { setMostraModaleSuccesso(false) }}
      />

      <ModalConferma
        mostra={mostraModaleErrore}
        setMostra={setMostraModaleErrore}
        mode="error"
        titolo="Salvataggio impostazioni"
        messaggio="Errore nel salvataggio delle impostazioni"
        bodyAlign="text-center"
        btnConferma="Chiudi"
        azioneConferma={() => { setMostraModaleErrore(false) }}
      />
    </>
  )
}