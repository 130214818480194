import { useState, useRef, useMemo } from "react";
import { Form, InputGroup, Button, ListGroup } from "react-bootstrap";
import axios from "axios";
import { BsFillGeoAltFill } from 'react-icons/bs'
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";


export default function MappaEIndirizzi({ posizione, setPosizione, indirizzo, setIndirizzo }) {
    const ResizeMap = () => {
        const map = useMap();
        map._onResize();
        return null;
      };
    //gestione mappa
    let campo_indirizzo = useRef(null);

    const [indirizziMultipli, setIndirizziMultipli] = useState([])
    const [erroriCompilazione, setErroriCompilazione] = useState({ indirizzo: "", categoria: "", oggetto: "", descrizione: "", allegati: "", email: "" })
    const map = useRef(null)
    const markerRef = useRef(null)
   


    // MAPPA
    const localizzaSullaMappa = () => {
        let ci = campo_indirizzo
        setErroriCompilazione("")
        let indirizzo_per_ricerca = indirizzo.replace(" ", "+")
        axios.get('https://nominatim.openstreetmap.org/search?format=json&q=' + indirizzo_per_ricerca)
            .then((res) => {
                if (res.data.length === 1) {
                    // unico risultato
                    setIndirizzo(res.data[0].display_name)
                    setPosizione([res.data[0].lat, res.data[0].lon])
                    map.current.flyTo([res.data[0].lat, res.data[0].lon], 13)
                    ci.classList.remove("is-invalid")
                } else if (res.data.length > 1) {
                    //più di un risultato
                    setIndirizziMultipli(res.data)
                    setErroriCompilazione(old => ({ ...old, indirizzo: "A questo indirizzo corrispondono più risultati, aggiungi qualche altra informazione o scegli dal seguente elenco." }))
                } else {
                    // nessun risultato
                    setPosizione([])
                    setErroriCompilazione(old => ({ ...old, indirizzo: "Questo indirizzo non può essere localizzato sulla mappa, correggi gli errori." }))
                }
            }).catch(() => {
                // nessun risultato
                setPosizione([])
                setErroriCompilazione(old => ({ ...old, indirizzo: "Si è verificato un errore nella ricerca. Riprova" }))

            })
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            localizzaSullaMappa()
        }
    }

    //sposta il marker e salva posizione raggiunta
    const eventHandlers = useMemo(
        () => ({
            dragend() {
                const marker = markerRef.current
                if (marker != null) {
                    setPosizione([marker.getLatLng().lat, marker.getLatLng().lng])
                    axios.get("https://nominatim.openstreetmap.org/reverse?format=json&lat="
                        + marker.getLatLng().lat + "&lon=" + marker.getLatLng().lng)

                        .then(res => {
                            setIndirizzo(res.data.display_name)
                        })
                        .catch(err => console.log(err))
                }
            },
        }),
        // eslint-disable-next-line
        [],
    )



    return (
        <>
            <InputGroup>
                <Form.Control placeholder="Inserisci il nome della via o della piazza, poi premi il pulsante o premi invio"
                    value={indirizzo}
                    ref={el => campo_indirizzo = el}
                    onChange={(ev) => { setIndirizzo(ev.target.value) }}
                    onKeyPress={handleKeyPress}
                />
                <Button onClick={localizzaSullaMappa}>
                    Localizza sulla mappa <BsFillGeoAltFill /></Button>
            </InputGroup>
            {erroriCompilazione.indirizzo &&
                <Form.Label className="mt-1 text-danger d-block"><strong>
                    {erroriCompilazione.indirizzo}
                </strong>
                </Form.Label>}
            <ListGroup>
                {indirizziMultipli.map((el, index) => (
                    <ListGroup.Item action key={index} onClick={() => {
                        setIndirizzo(el.display_name)
                        setPosizione([el.lat, el.lon])
                        setIndirizziMultipli([])
                        setErroriCompilazione("")
                        map.current.flyTo([el.lat, el.lon], 13)
                    }}>{el.display_name}</ListGroup.Item>
                ))}
            </ListGroup>
            {posizione[0] !== 0 && 
            <MapContainer
            className="mx-1 mt-1"
            style={{ height: "40vh" }}
            ref={map}
            center={posizione}
            zoom={13}
                scrollWheelZoom={false}
            >
                 <ResizeMap />
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                {posizione.length > 0 &&
                    <Marker
                        draggable={true}
                        eventHandlers={eventHandlers}
                        position={posizione}
                        ref={markerRef}
                        >
                        <Popup minWidth={90}>
                            <span>{indirizzo}</span>
                        </Popup>
                    </Marker>
                }
            </MapContainer>
    }
        </>
    )
}