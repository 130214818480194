
import { Row, Table, InputGroup, FormControl, Col, Button } from "react-bootstrap";
import { GoSearch } from "react-icons/go"
import {  useState } from "react";
import AzioniPerVerificati from "./AzioniPerVerificati";
import BottoneFile from '../../Utilities/BottoneFile';
import AggiungiPartecipanteModal from "./AggiungiPartecipanteModal";
import { BsPlusLg } from "react-icons/bs"

export default function GestionePartecipanti({ 
    comId, 
    setShowToast, 
    setMessaggioToast, 
    setTitoloToast,
    setRicaricaPartecipanti, 
    partecipanti }) {
        const [showAggiungiPartecipante, setShowAggiungiPartecipante] = useState(false)
    const [ricercaPartecipanti, setRicercaPartecipanti] = useState("")

    return (
        <div className="container-sm  p-0 p-md-4">

            <Row className="d-flex justify-content-between mb-3">
                <Col md={4} xs={12}>
                    <h4 className="w-auto"><strong>Partecipanti</strong></h4>
                </Col>
                <Col md={5} xs={12} className="text-md-end mb-2 mb-md-0">
                        <Button className="w-auto"
                            onClick={() => { setShowAggiungiPartecipante(true) }}
                        >Aggiungi un partecipante <BsPlusLg className="m-auto" /></Button>
                    </Col>
                <Col md={3} xs={12}>
                    <InputGroup>
                        <InputGroup.Text id="basic-addon1">
                            <GoSearch />
                        </InputGroup.Text>
                        <FormControl placeholder="Cerca nome..."
                            onChange={(ev) => { setRicercaPartecipanti(ev.target.value) }}
                        />
                    </InputGroup>
                </Col>
            </Row>

            <BottoneFile comId={comId}
                setShowToast={setShowToast}
                setMessaggioToast={setMessaggioToast}
                setTitoloToast={setTitoloToast}
                setRicaricaPartecipanti={setRicaricaPartecipanti}
            />

            <Table className='mt-3' striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Cognome</th>
                        <th>Nome</th>
                        <th>Codice Fiscale</th>
                        <th >Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {partecipanti.length > 0 &&
                        partecipanti
                            .filter(el => {
                                if (ricercaPartecipanti) {
                                    let nome_cognome = el.datiPartecipante.dparNome + " " + el.datiPartecipante.dparCognome
                                    return nome_cognome.toLowerCase().includes(ricercaPartecipanti)
                                }
                                else return true
                            })
                            .sort((a, b) => {
                                // Ordinamento per stato (attivo prima di bloccato)
                              
                                // Se lo stato è lo stesso, ordina per cognome e poi per nome
                                const cognomeA = a.datiPartecipante.dparCognome.toLowerCase();
                                const cognomeB = b.datiPartecipante.dparCognome.toLowerCase();
                                const nomeA = a.datiPartecipante.dparNome.toLowerCase();
                                const nomeB = b.datiPartecipante.dparNome.toLowerCase();
                        
                                if (cognomeA < cognomeB) return -1;
                                if (cognomeA > cognomeB) return 1;
                                if (nomeA < nomeB) return -1;
                                if (nomeA > nomeB) return 1;
                                return 0;
                            })
                            .map((el, index) => (
                                <tr key={index} className={el.parStato === 'bloccato' ? "row-blocked":''}>
                                    <td>{el.datiPartecipante.dparCognome}</td>
                                    <td>{el.datiPartecipante.dparNome}</td>
                                    <td>{el.datiPartecipante.dparCf}</td>
                                    <td>
                                            <AzioniPerVerificati partecipante={el} 
                                            setRicaricaPartecipanti={setRicaricaPartecipanti} />
                                    </td>
                                </tr>
                            ))}
                </tbody>
            </Table>
        
        <AggiungiPartecipanteModal
                show={showAggiungiPartecipante}
                setShow={setShowAggiungiPartecipante}
                comId={comId}
                setShowToast={setShowToast}
                setMessaggioToast={setMessaggioToast}
                setTitoloToast={setTitoloToast}
                setRicaricaPartecipanti={setRicaricaPartecipanti} />
        </div>
    )
}