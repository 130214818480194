
import { Form, Button, ButtonGroup, Row, Col } from "react-bootstrap";
import { useState, useRef } from "react";


import { FiUpload } from 'react-icons/fi'
import { BsFileEarmarkRichtext } from "react-icons/bs"
import { GoTrash } from "react-icons/go"
import { IoPersonAddSharp } from "react-icons/io5";
import PartecipanteService from "../../services/PartecipanteService";
export default function BottoneFile(props) {

    //stato per i file caricati, metadata e arrayBuffer
    const [uploadedFilesMetadata, setUploadedFilesMetadata] = useState([])
    // const acceptedExtensions = ["image/*", "image/png", "image/jpg", "image/jpeg"]

    //stato per mostrare alert di errore
    const [erroriImport, setErroriImport] = useState("")

    let inputFile = useRef(null)

    const handleUpload = (event) => {
        event.preventDefault()
        inputFile?.click();
    };

    const deleteFile = () => {
        setUploadedFilesMetadata([])
        inputFile.value=""
    }

    //FILE
    const handleDisplayFileDetails = async () => {
        if (inputFile?.files && inputFile.files[0]
            && inputFile.files[0].size <= 10000 * 1024 //controlla grandezza file < 10000kB 
          /*  && acceptedExtensions.includes(inputFile.files[0].type) //controlla estensione */
            && uploadedFilesMetadata.length < 1  //controlla # allegati
        ) {
            setUploadedFilesMetadata(old => [...old, inputFile.files[0]])
            setErroriImport("")
        } else {
            if (uploadedFilesMetadata.length >= 1) setErroriImport("Non puoi allegare più di un allegato.")
            else if (uploadedFilesMetadata.filter(file => file.name === inputFile.files[0].name).length > 1) setErroriImport("Non puoi caricare file con lo stesso nome.")
            //else if (!acceptedExtensions.includes(inputFile.files[0].type)) setErroriImport("Il formato del file scelto non è accettabile.")
            else if (!inputFile.files[0].size <= 102400) setErroriImport("Il file scelto è troppo grande. Riprova.")
        }

    }

    const handleSubmit = () => {
            var formData = new FormData()
        uploadedFilesMetadata.forEach(el => {
            formData.append("file", el)
        })

        console.log(formData)

        PartecipanteService.importPartecipanti( props.comId, formData)
            .then(() => {
                props.setTitoloToast("Caricamento CSV partecipanti")
                props.setMessaggioToast("La registrazione dei nuovi partecipanti é stata eseguita con successo!")
                props.setShowToast(true)
                props.setRicaricaPartecipanti(true)
                //setMostraModaleOk(true)
           })
            .catch((err) => {
              setErroriImport(err.response.data)
            })
    }

    return (
        <>
            <Row>
                <Col>
                    <input
                        className="d-none"
                        type="file"
                        accept=".csv"
                        ref={el => inputFile = el}
                        onInput={handleDisplayFileDetails} />
                    <Button className="mb-3"
                        onClick={handleUpload} >
                        <FiUpload className="me-2" size="1em" /> Carica
                    </Button>
                </Col>
                <Col xs={8} className="text-center">
                    {uploadedFilesMetadata.length > 0 && uploadedFilesMetadata.map((el, i) => (
                        <ButtonGroup key={i} className="me-3 mb-3">
                            <Button variant="outline-primary" > <BsFileEarmarkRichtext size="1.5em" /> {el.name}</Button>
                            <Button variant="outline-danger" onClick={() => { deleteFile(el.name) }}> <GoTrash size="1.5em" /></Button>
                        </ButtonGroup>
                    ))}
                </Col>
                <Col className="text-center">
                    {uploadedFilesMetadata.length > 0 &&
                        <Button className="mb-3"  onClick={handleSubmit}                       >
                            <IoPersonAddSharp className="me-2" size="1em" /> Elabora
                        </Button>
                    }
                </Col>
            </Row>
            {erroriImport &&
                <Form.Label className="mt-1 text-danger d-block"><strong>
                    {erroriImport}
                </strong>
                </Form.Label>}
        </>
    )
}