import { useState, useEffect } from "react";
import { Container, Tab, Tabs, OverlayTrigger, Popover } from "react-bootstrap";
import { BsQuestionCircle } from "react-icons/bs"
import SegnalazioniService from "../../../services/SegnalazioniService";
import TabellaSegnalazioni from "./TabellaSegnalazioni";
import GestisciSegnalazione from "./GestisciSegnalazione";
import ImpostazioniSegnalazioni from "./ImpostazioniSegnalazioni";
import ModuloNonAttivo from "../ModuloNonAttivo";
import { useNavigate, useLocation } from "react-router-dom";
import PaginaNonTrovata from "../../Utente/Error/PaginaNonTrovata";

export default function Segnalazioni(props) {
    const navigate = useNavigate();
    const location = useLocation();

    // Fetch segnalazioni
    let [segnalazioni, setSegnalazioni] = useState([])

    //stato per ricaricare le segnalazioni al termine di un cambio dei dati su db
    let [ricaricaSegnalazioni, setRicaricaSegnalazioni] = useState(true)

    let [statoFilter, setStatoFilter] = useState("")
    let [categoriaFilter, setCategoriaFilter] = useState("")

    useEffect(() => {
        if (ricaricaSegnalazioni && props.comune.comId) {
            SegnalazioniService.findByComune(props.comune.comId)
                .then(res => {
                    setSegnalazioni(res.data)
                })
                .catch(() => {
                    setSegnalazioni([])
                })
                .finally(() => {
                    setRicaricaSegnalazioni(false)
                })
        }
    }, [ricaricaSegnalazioni, props.comune]);

    //stato che passa l'id della segnalazione da mostrare per i componenti 
    let [segId, setSegId] = useState("")
        //stato che decide come deve essere gestita la segnalazione
        let [gestisci, setGestisci] = useState("")


    const toggleTab = (ev) => {
        if (ev.target.dataset.rrUiEventKey === "impostazioni") {
            props.setTabAttivaSegnalazioni("impostazioni")
            navigate("/admin/segnalazioni#impostazioni")
        }
        if (ev.target.dataset.rrUiEventKey === "segnalazioni") {
            props.setTabAttivaSegnalazioni("segnalazioni")
            navigate("/admin/segnalazioni#tutte-le-segnalazioni")
        }
    }

    useEffect(() => {
        if (location.hash === "#tutte-le-segnalazioni") props.setTabAttivaSegnalazioni("segnalazioni")
        if (location.hash === "#impostazioni") props.setTabAttivaSegnalazioni("impostazioni")
    }, [props, location])

    if (props.partecipante.parId && props.partecipante.ruolo.ruoFlAmministratore) {

    return (
        <Container fluid="md">
            <div className="d-flex justify-content-between">
                <h1 className="title">Segnalazioni</h1>
                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloSegnalazioniAttivo &&
                    <OverlayTrigger placement="left"  trigger="click" rootClose
                        overlay={
                            <Popover id="popover-contained" >
                                <Popover.Header as="h3">Aiuto:</Popover.Header>
                                <Popover.Body>
                                    Ecco tutte le segnalazioni inviate dai partecipanti.
                                    <br />
                                    <br />
                                    Possibili <strong>stati</strong>:
                                    <ul>
                                        <li><strong>In attesa di risposta</strong>: segnalazione inviata dal partecipante</li>
                                        <li><strong>Chiusa</strong>: segnalazione che è stata presa in carico dal Comune</li>
                                    </ul>
                                    <br />
                                    Possibili <strong>azioni</strong>:
                                    <ul>
                                        <li><strong>Rispondi</strong>: per inviare una risposta alla segnalazione e chiuderla</li>
                                    </ul>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <div className="my-auto" style={{cursor:"pointer"}}>
                            <BsQuestionCircle size="2em" className="my-auto" />
                        </div>
                    </OverlayTrigger>
                }
            </div>
            {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloSegnalazioniAttivo ? (
                <Tabs activeKey={props.tabAttivaSegnalazioni} id="uncontrolled-tab-example" className="mb-3"
                    onClick={toggleTab}>
                    <Tab eventKey="segnalazioni" title="Segnalazioni">
                        {props.mostraTabella ?
                            <TabellaSegnalazioni
                                comune={props.comune}
                                segnalazioni={segnalazioni}
                                setSegId={setSegId}
                                setMostraTabella={props.setMostraTabella}
                                statoFilter={statoFilter}
                                setStatoFilter={setStatoFilter}
                                categoriaFilter={categoriaFilter}
                                setCategoriaFilter={setCategoriaFilter}
                                setGestisci={setGestisci}
                            />
                            :
                            <GestisciSegnalazione
                                segnalazioni={segnalazioni}
                                segId={segId}
                                setMostraTabella={props.setMostraTabella}
                                setRicaricaSegnalazioni={setRicaricaSegnalazioni}
                                gestisci={gestisci}
                                setGestisci={setGestisci}
                            />
                        }
                    </Tab>
                    <Tab eventKey="impostazioni" title="Impostazioni">
                        <ImpostazioniSegnalazioni
                            comune={props.comune}
                            segnalazioni={segnalazioni}
                            setRicaricaComune={props.setRicaricaComune}
                        />
                    </Tab>
                </Tabs>
            ) : (
                <ModuloNonAttivo moduleName="Segnalazioni" />
            )}
        </Container >
    )
}else{
    return(<PaginaNonTrovata/>)
}
}