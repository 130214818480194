import { Container, Form, Row, Button } from "react-bootstrap";
import { useState, useEffect, useRef } from "react";
import ModalConferma from "../../Utilities/ModalConferma";
import impostazioniComuneServiceInstance from "../../../services/ImpostazioniComuneService";

export default function ImpostazioniModuli(props) {
    // Riferimento al form (posizione 0)
    let ref = useRef([]);
    const [validated, setValidated] = useState(false);

    const [mostraModalOk, setMostraModalOk] = useState(false)
    const [mostraModalFailed, setMostraModalFailed] = useState(false)
    const [mostraModalCancelOk, setMostraModalCancelOk] = useState(false)

    const [labelBilanci, setLabelBilanci] = useState("")

    useEffect(() => {
        setLabelBilanci(
            props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcLabelModuloBilanci
                ? props.comune.impostazioniComune.imcLabelModuloBilanci
                : ""
        );
    }, [props.comune])

    const handleSubmit = () => {
        let impostazioniComune = {
            comune: {
                comId: props.comune.comId
            },
            ...props.comune.impostazioniComune,
            "imcLabelModuloBilanci": labelBilanci
        }

        setMostraModalCancelOk(false)
        impostazioniComuneServiceInstance.aggiornaImpostazioni(impostazioniComune)
            .then(() => {
                props.setRicaricaComune(true)
                setMostraModalOk(true)
            })
            .catch(() => {
                setMostraModalFailed(true)
            })

    }

    const closeOkModal = () => {
        setMostraModalOk(false)
    }

    const closeOkFailed = () => {
        setMostraModalFailed(false)
    }

    const checkForm = () => {
        setValidated(true);
        if (ref.checkValidity() === true)
            setMostraModalCancelOk(true)
    }

    return (
        <>
            <Container fluid="md">
                <Form noValidate validated={validated} onSubmit={handleSubmit} ref={el => ref = el} >
                    {
                        props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloBilanciAttivo &&
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label column sm="4">
                                    <strong>Label modulo bilanci partecipativi:</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Inserisci la nuova label per il modulo bilanci partecipativi"
                                    value={labelBilanci}
                                    onChange={(ev) => setLabelBilanci(ev.target.value)}
                                />
                            </Form.Group>
                        </Row>
                    }

                    {
                        props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloPetizioniAttivo &&
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label column sm="4">
                                    <strong>Label modulo petizioni: &nbsp;</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Inserisci la nuova label per il modulo petizioni"
                                    defaultValue={props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcLabelModuloPetizioni}
                                    disabled
                                />
                            </Form.Group>
                        </Row>

                    }

                    {
                        props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloReferendumAttivo &&
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label column sm="4">
                                    <strong>Label modulo referendum: &nbsp;</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Inserisci la nuova label per il modulo petizioni"
                                    defaultValue={props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcLabelModuloReferendum}
                                    disabled
                                />
                            </Form.Group>
                        </Row>
                    }
                    {
                        props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloSegnalazioniAttivo &&
                        <Row>
                            <Form.Group className="mb-3">
                                <Form.Label column sm="4">
                                    <strong>Label modulo segnalazioni: &nbsp;</strong>
                                </Form.Label>
                                <Form.Control
                                    type="text"
                                    required
                                    placeholder="Inserisci la nuova label per il modulo petizioni"
                                    defaultValue={props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcLabelModuloSegnalazioni}
                                    disabled
                                />
                            </Form.Group>

                        </Row>
                    }
                </Form>
                <Container className="d-flex justify-content-end w-90 p-0 pt-3 pb-3">
                    <Button onClick={checkForm}>Aggiorna</Button>
                </Container>
            </Container >

            <ModalConferma
                mostra={mostraModalOk}
                setMostra={setMostraModalOk}
                mode="success"
                titolo="Salvataggio impostazioni"
                messaggio="Impostazioni salvate con successo"
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={closeOkModal}
            />

            <ModalConferma
                mostra={mostraModalFailed}
                setMostra={setMostraModalFailed}
                mode="error"
                titolo="Salvataggio impostazioni"
                messaggio="Errore nel salvataggio delle impostazioni"
                bodyAlign="text-center"
                btnConferma="Chiudi"
                azioneConferma={closeOkFailed}
            />

            <ModalConferma
                mostra={mostraModalCancelOk}
                setMostra={setMostraModalCancelOk}
                mode="primary"
                titolo="Salvataggio impostazioni"
                messaggio="Confermi il salvataggio delle impostazioni?"
                bodyAlign="text-center"
                btnAnnulla="No, annulla"
                btnConferma="Sì, salva"
                azioneConferma={handleSubmit}
            />
        </>
    )
}