import { useState, useEffect } from "react";
import { Container, Tab, Tabs, OverlayTrigger, Popover } from "react-bootstrap";
import { BsQuestionCircle } from "react-icons/bs"
import ImpostazioniPetizioni from "./ImpostazioniPetizioni";
import TabellaPetizioni from "./TabellaPetizioni";
import GestisciPetizione from "./GestisciPetizione";
import PetizioniService from "../../../services/PetizioniService";
import ModuloNonAttivo from "../ModuloNonAttivo";
import { useNavigate, useLocation } from "react-router-dom";
import PaginaNonTrovata from "../../Utente/Error/PaginaNonTrovata";

export default function Petizioni(props) {

    const navigate = useNavigate();
    const location = useLocation();

    // Fetch petizioni
    let [petizioni, setPetizioni] = useState([])

    //ricaricaPetizioni bit
    let [ricaricaPetizioni, setRicaricaPetizioni] = useState(true)

    let [statoFilter, setStatoFilter] = useState("")
    let [categoriaFilter, setCategoriaFilter] = useState("")

    useEffect(() => {
        if (ricaricaPetizioni && props.comune.comId) {
            PetizioniService.findByComune(props.comune.comId)
                .then(res => {
                    setPetizioni(res.data)
                })
                .catch(() => {
                    setPetizioni([])
                })
                .finally(() => {
                    setRicaricaPetizioni(false)
                })
        }
    }, [ricaricaPetizioni, props.comune]);

    //stato che passa l'id della petizione da mostrare per i componenti 
    // - GestisciPetizione
    let [petId, setPetId] = useState("")

    //stato che decide come deve essere gestita la petizione
    let [gestisci, setGestisci] = useState("")

    const toggleTab = (ev) => {
        if (ev.target.dataset.rrUiEventKey === "impostazioni") {
            props.setTabAttivaPetizioni("impostazioni")
            navigate("/admin/petizioni#impostazioni")
        }
        if (ev.target.dataset.rrUiEventKey === "petizioni") {
            props.setTabAttivaPetizioni("petizioni")
            navigate("/admin/petizioni#tutte-le-petizioni")
        }
    }

    useEffect(() => {
        if (location.hash === "#tutte-le-petizioni") props.setTabAttivaPetizioni("petizioni")
        if (location.hash === "#impostazioni") props.setTabAttivaPetizioni("impostazioni")
    }, [props, location])


    if (props.partecipante.parId && props.partecipante.ruolo.ruoFlAmministratore) {
        return (
            <Container fluid="sm">
                <div className="d-flex justify-content-between">
                    <h1 className="title">Petizioni</h1>
                    {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloPetizioniAttivo &&
                        <OverlayTrigger placement="left"  trigger="click" rootClose
                            overlay={
                                <Popover id="popover-contained" >
                                    <Popover.Header as="h3">Aiuto:</Popover.Header>
                                    <Popover.Body>
                                        Ecco tutte le petizioni inviate dai partecipanti.
                                        <br />
                                        <br />
                                        Possibili <strong>stati</strong>:
                                        <ul>
                                            <li><strong>In validazione</strong>: petizione non visibile ai partecipanti</li>
                                            <li><strong>Rifiutata</strong>: petizione di cui è visualizzabile solo il titolo</li>
                                            <li><strong>Raccolta firme</strong>: i partecipanti possono apporre la propria firma su questa petizione</li>
                                            <li><strong>Conclusa</strong>: petizione per cui è stata pubblicata la risposta dal comune</li>
                                        </ul>
                                        <br />
                                        Possibili <strong>azioni</strong>:
                                        <ul>
                                            <li><strong>Controlla</strong>: per rifiutare o pubblicare la petizione</li>
                                            <li><strong>Rispondi</strong>: per inserire la risposta del comune</li>
                                            <li><strong>Registra firme</strong>: per registrare le firme raccolte al di fuori della piattaforma</li>
                                        </ul>
                                    </Popover.Body>
                                </Popover>
                            }
                        >
                            <div className="my-auto" style={{cursor:"pointer"}} >
                            <BsQuestionCircle size="2em" className="my-auto" />
                            </div>
                        </OverlayTrigger>
                    }
                </div>
                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloPetizioniAttivo ? (
                    <Tabs activeKey={props.tabAttivaPetizioni} id="uncontrolled-tab-example" className="mb-3"
                        onClick={toggleTab}>
                        <Tab eventKey="petizioni" title="Petizioni">
                            {props.mostraTabella ?
                                <TabellaPetizioni
                                    comune={props.comune}
                                    petizioni={petizioni}
                                    setPetId={setPetId}
                                    setMostraTabella={props.setMostraTabella}
                                    setGestisci={setGestisci}
                                    statoFilter={statoFilter}
                                    setStatoFilter={setStatoFilter}
                                    categoriaFilter={categoriaFilter}
                                    setCategoriaFilter={setCategoriaFilter}
                                /> :
                                <GestisciPetizione
                                    comune={props.comune}
                                    petizioni={petizioni}
                                    petId={petId}
                                    setMostraTabella={props.setMostraTabella}
                                    gestisci={gestisci}
                                    setGestisci={setGestisci}
                                    setRicaricaPetizioni={setRicaricaPetizioni}
                                />
                            }
                        </Tab>
                        <Tab eventKey="impostazioni" title="Impostazioni">
                            <ImpostazioniPetizioni
                                comune={props.comune}
                                petizioni={petizioni}
                                setRicaricaComune={props.setRicaricaComune}
                            />
                        </Tab>
                    </Tabs>
                ) : (
                    <ModuloNonAttivo moduleName="Petizioni" />
                )}
            </Container >
        )
    } else {
        return (
            <PaginaNonTrovata />
        )
    }
}