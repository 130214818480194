import { useState, useEffect } from "react"
import { Row, Col, Table, Dropdown, NavDropdown, FormControl, InputGroup, Form, Badge, CloseButton,  Button } from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import NumberFormat from "react-number-format"
import AzioniTabella from "./AzioniTabella";
import { getPetizioneColor } from '../../Utilities/getBorderColor'
import { getStatusOrderPetizione } from '../../Utilities/getStatusOrder'
import { getStatusPetizione } from "../../Utilities/getStatus"
import { statiPetizione } from "../../Utilities/statiElementEnum";
import { PiFileCsv } from "react-icons/pi";
import { convertPetizioniToCSV } from "../../Utilities/covertToCSV";
const dayjs = require('dayjs');

export default function TabellaPetizioni(props) {

    let categorie = (props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcCategoriePetizioni && props.comune.impostazioniComune.imcCategoriePetizioni.categorie_petizioni) || ["Caricamento..."]
    let [petizioni, setPetizioni] = useState([])

    const [titolo, setTitolo] = useState("")

    const getPetizioni = () => {
        let ret = petizioni

        if (props.statoFilter.length > 0)
            ret = ret.filter((pet) => pet.petStato === props.statoFilter)

        if (props.categoriaFilter.length > 0)
            ret = ret.filter((pet) => pet.petCategoria === props.categoriaFilter)

        if (titolo)
            ret = ret.filter((pet) => pet.petTitolo.toLowerCase().includes(titolo.toLowerCase()))

        return ret
    }

    let filterByStato = (stato) => {
        props.setStatoFilter(stato)
    }

    let resetStato = () => {
        props.setStatoFilter("")
    }

    let filterByCategoria = (categoria) => {
        props.setCategoriaFilter(categoria)
    }

    let resetCategoria = () => {
        props.setCategoriaFilter("")
    }


// Funzione per scaricare il CSV
const handleDownloadReports = () => {
    const csvContent = convertPetizioniToCSV(props.petizioni, props.comune); // Converti i dati in CSV
    const url = window.URL.createObjectURL(new Blob([csvContent])); // Crea l'URL
    const link = document.createElement('a');
    const fileName = `Petizioni_${dayjs(new Date()).format("DDMMYYHHmm")}.csv`;
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

    useEffect(() => {
        let petizioni = []
        props.petizioni.forEach(pet => {
            let petizione = {
                petId: pet.petId,
                petTitolo: pet.petTitolo,
                petPartecipante: pet.partecipante.datiPartecipante.dparNome + " " + pet.partecipante.datiPartecipante.dparCognome,
                petCategoria: pet.petCategoria,
                petStato: getStatusPetizione(pet, props.comune),
                petFirme: pet.petFirmeOffline + pet.firmePetizione.length,
                petFineRaccoltaFirme: pet.petFineRaccoltaFirme,
                petDataPubblicazione: pet.petDataPubblicazione ? dayjs(pet.petDataPubblicazione).format('DD/MM/YYYY') : "---"
            }
            petizioni.push(petizione)
        })

        setPetizioni(petizioni)
    }, [props])

    return (
        <>
            <Row className="d-flex justify-content-between  my-4">
                <Col>
                    <Dropdown className="d-inline" autoClose="inside">
                        <Dropdown.Toggle id="dropdown-autoclose-outside">
                            <span className="px-5">Filtra</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <NavDropdown title="Stato" className="noChangeOnClick" drop="end" onSelect={filterByStato}>
                                {Object.values(statiPetizione)
                                    .map((stato, index) => (
                                        <NavDropdown.Item key={index} eventKey={stato}>{stato}</NavDropdown.Item>
                                    )
                                    )}
                            </NavDropdown>
                            <NavDropdown title="Categoria" className="noChangeOnClick" drop="end" onSelect={filterByCategoria}>
                                {
                                    categorie.map((categoria, index) => (
                                        <NavDropdown.Item key={index} eventKey={categoria}>{categoria}</NavDropdown.Item>
                                    ))
                                }
                            </NavDropdown>
                        </Dropdown.Menu>
                    </Dropdown>
                    {
                        props.statoFilter.length > 0 &&
                        <Form.Label className="mx-4 mt-2 mt-md-0 d-block">
                            Stato: <strong> {props.statoFilter}</strong><Badge bg="outline-danger"><CloseButton onClick={resetStato} /></Badge>
                        </Form.Label>
                    }
                    {
                        props.categoriaFilter.length > 0 &&
                        <Form.Label className="mx-4  mt-2 mt-md-0 d-block">
                            Categoria: <strong> {props.categoriaFilter}</strong><Badge bg="outline-danger"><CloseButton onClick={resetCategoria} /></Badge>
                        </Form.Label>
                    }
                </Col>

                <Col xs={12} md={5} className="d-flex justify-content-end">
                    <InputGroup className="mt-2 mt-md-0" style={{maxHeight:"1rem"}}>
                        <InputGroup.Text id="basic-addon1">
                            <GoSearch />
                        </InputGroup.Text>
                        <FormControl placeholder="Cerca titolo..."
                            onChange={(ev) => { setTitolo(ev.target.value) }}
                        />
                    </InputGroup>
                </Col>

                <Col className="text-end" xs={{span:12, order:4}} md={{span:3, order:4}}>  
                        <Button onClick={handleDownloadReports} variant="primary" >
                            <PiFileCsv className="me-1" />
                            Esporta tabella in formato csv
                        </Button>
                    </Col>
            </Row>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Titolo</th>
                        <th>Autore</th>
                        <th className="text-center">Categoria</th>
                        <th className="text-center">Stato</th>
                        <th className="text-center">Firme</th>
                        <th className="text-center nowrap">Pubblicata il</th>
                        <th className="text-center">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getPetizioni().length > 0 ? (
                            getPetizioni()
                                .sort((a, b) => { return getStatusOrderPetizione(a.petStato) - getStatusOrderPetizione(b.petStato) })
                                .map(petizione => (
                                    <tr key={petizione.petId}>
                                        <td>{petizione.petTitolo}</td>
                                        <td>{petizione.petPartecipante}</td>
                                        <td className="text-center">{petizione.petCategoria}</td>
                                        <td className="text-center">
                                            <Badge pill bg={getPetizioneColor(petizione)} className="pillText" style={{ minWidth: "90%" }}>
                                                {petizione.petStato}
                                            </Badge>
                                        </td>
                                        <td className="text-center">
                                            <NumberFormat
                                                displayType="text"
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                value={petizione.petFirme}
                                            />
                                        </td>
                                        <td className="text-center">{petizione.petDataPubblicazione}</td>
                                        <td>
                                            <AzioniTabella
                                                petId={petizione.petId}
                                                setPetId={props.setPetId}
                                                stato={getStatusPetizione(petizione, props.comune)}
                                                setMostraTabella={props.setMostraTabella}
                                                setGestisci={props.setGestisci}
                                            />
                                        </td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td colSpan="7">
                                    Nessuna petizione presente
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    )

}