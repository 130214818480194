import { Navbar, Container, Nav, NavDropdown } from "react-bootstrap";
import logoComuneDefault from '../../../immagini/stemma_comune_prova/stemma_comune_di_prova_light.png'
import { useLocation } from 'react-router-dom';
import { FiLogOut, FiLogIn } from 'react-icons/fi'
import { BsPersonCircle } from 'react-icons/bs'
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import ModalPermessi from "../../Utilities/ModalPermessi";
import { useEffect } from "react";

export default function NavbarUtente(props) {
    const location = useLocation();

    const usePathname = () => {
        const location = useLocation();
        return location.pathname;
    }
    // eslint-disable-next-line
    const [url, setUrl] = useState(useLocation().pathname)
    const navigate = useNavigate();

    const goToPath = (path) => {
        if (location.state){
            navigate(path, { state: {
                prevPath: location.pathname,
                petId: location.state.petId,
                refId: location.state.refId,
                bilId: location.state.bilId,
                proId: location.state.proId
            }})
        } else
            navigate(path, { state: { prevPath: location.pathname } })
    }

    const [mostraModalPermessi, setMostraModalPermessi] = useState(false);
    //stati

    const [titolo, setTitolo] = useState();
    const [messaggio, setMessaggio] = useState();
    const [mode, setMode] = useState();

    const getLogoUrl = () => {
        if (props.comune.comStemmaFile)
            return "data:image/png;base64," + props.comune.comStemmaFile
        else
            return logoComuneDefault
    }

    const IsLogged = (path) => {
        // 1. no permesso visua elemento
        //2. non loggato
        //3. con permessi
        if ((props.partecipante && props.partecipante.ruolo
            && props.partecipante.ruolo.ruoFlVisualizzatore) ||
            !(props.partecipante && props.partecipante.parId)) {
            //caso 3
            goToPath(path)
        } else {
            // caso 2
            setTitolo("Attenzione: operazione illecita")
            setMessaggio(`Attualmente non possiedi i permessi per visualizzare ${props.mode === "petizione" ? "la petizione" : "il referendum"}. L'operazione è bloccata!`)
            setMode("danger")
            setMostraModalPermessi(true)
        }
    }

    const logout = () => {
        sessionStorage.removeItem("partecipante")
        sessionStorage.removeItem("token")
    }

    useEffect(() => {
        setUrl(location.pathname)
    }, [location])

    return (
        <>
            <Navbar bg="primary" expand="lg" variant="dark" sticky="top">
                <Container >
                    <Navbar.Brand href={props.comune.comSito || "/"}>
                        <img alt="Logo comune" src={getLogoUrl()} width="50" height="45" />
                        <span>{props.comune.comNome}</span>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="d-flex w-100 justify-content-between">
                            <div className="d-lg-flex flex-row">
                                <Nav.Link className="moduleLink" active={usePathname() === "/"} href="/">
                                    Home
                                </Nav.Link>
                                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloPetizioniAttivo &&
                                    <Nav.Link className="moduleLink" active={url.includes("petizion")}
                                        onClick={() => { IsLogged("/esplora-petizioni") }}>
                                        Petizioni
                                    </Nav.Link>
                                }
                                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloReferendumAttivo &&
                                    <Nav.Link className="moduleLink" active={url.includes("referendum")}
                                        onClick={() => { IsLogged("/esplora-referendum") }}>
                                        Referendum
                                    </Nav.Link>
                                }
                                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloSegnalazioniAttivo &&
                                    <Nav.Link className="moduleLink" active={url.includes("segnalazion")}
                                        onClick={() => { IsLogged("/esplora-segnalazioni") }}>
                                        Segnalazioni
                                    </Nav.Link>
                                }
                                {props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcModuloBilanciAttivo &&
                                    <Nav.Link className="moduleLink" active={url.includes("bilanc")}
                                        onClick={() => { IsLogged("/esplora-bilanci-partecipativi") }}>
                                       {props.comune.impostazioniComune.imcLabelModuloBilanci}
                                    </Nav.Link>
                                }
                            </div>

                            {props.partecipante.parId ?
                                <div className="d-lg-flex flex-row d-inline">
                                    {props.partecipante && props.partecipante.ruolo &&
                                        props.partecipante.ruolo.ruoFlAmministratore ?
                                        <div className="d-flex flex-row">
                                            <BsPersonCircle className="me-2 dark my-2" size="1.3em" />

                                            <NavDropdown className="w-50"
                                                title={props.partecipante && props.partecipante.datiPartecipante &&
                                                    props.partecipante.datiPartecipante.dparNome + ' ' +
                                                    props.partecipante.datiPartecipante.dparCognome}>
                                                <NavDropdown.Item href="/admin/cruscotto">Area amministratore</NavDropdown.Item>
                                            </NavDropdown>
                                        </div>

                                        :
                                        <>

                                            <Nav.Link active>
                                                <BsPersonCircle className="me-2" />
                                                {props.partecipante && props.partecipante.datiPartecipante &&
                                                    props.partecipante.datiPartecipante.dparNome}{' '}
                                                {props.partecipante && props.partecipante.datiPartecipante &&
                                                    props.partecipante.datiPartecipante.dparCognome}
                                            </Nav.Link>

                                        </>
                                    }

                                    <Nav.Link active href="/"
                                        onClick={logout}>
                                        <FiLogOut className="me-2" />Logout
                                    </Nav.Link>
                                </div>
                                :
                                <>
                                    <Nav.Link active
                                        onClick={() => { goToPath("/login") }}>
                                        <FiLogIn className="me-2" />Login
                                    </Nav.Link>
                                </>
                            }
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <ModalPermessi
                mostra={mostraModalPermessi}
                setMostra={setMostraModalPermessi}
                titolo={titolo}
                messaggio={messaggio}
                mode={mode}
            />
        </>
    )
}