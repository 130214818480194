import { useState } from "react"
import { Row, Col, Table, Dropdown, NavDropdown, FormControl, InputGroup, Form, Badge, CloseButton, Button} from "react-bootstrap"
import { GoSearch } from "react-icons/go"
import AzioniTabella from "./AzioniTabella";
import { getSegnalazioneColor } from "../../Utilities/getBorderColor";
import { getStatusOrderSegnalazione } from "../../Utilities/getStatusOrder";
import { statiSegnalazione } from "../../Utilities/statiElementEnum";
import { convertSegnalazioniToCSV } from "../../Utilities/covertToCSV";
import { PiFileCsv } from "react-icons/pi";

const dayjs = require('dayjs');
var relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(relativeTime)

export default function TabellaSegnalazioni(props) {

    let [oggetto, setOggetto] = useState("")

    const getSegnalazioni = () => {
        let ret = props.segnalazioni

        if (props.statoFilter.length > 0)
            ret = ret.filter((seg) => seg.segStato === props.statoFilter)

        if (props.categoriaFilter.length > 0)
            ret = ret.filter((seg) => seg.segCategoria === props.categoriaFilter)

        if (oggetto)
            ret = ret.filter((seg) => seg.segOggetto.toLowerCase().includes(oggetto.toLowerCase()))
        return ret
    }

    let filterByStato = (stato) => {
        props.setStatoFilter(stato)
    }

    let resetStato = () => {
        props.setStatoFilter("")
    }

    let filterByCategoria = (categoria) => {
        props.setCategoriaFilter(categoria)
    }

    let resetCategoria = () => {
        props.setCategoriaFilter("")
    }

    let scadenzaSegnalazione = (segnalazione) => {
        let fine = dayjs(segnalazione.segDataInvio).add(props.comune && props.comune.impostazioniComune && props.comune.impostazioniComune.imcScadenzaSegnalazioni, 'day')
        let x = dayjs(fine).to(dayjs(), 'd')
        if (x === "a month") return "un mese"
        else if (x.includes("days")) return x.replace("days", "giorni")
        else return x.replace("day", "giorno")
    }

 
    const formattaIndirizzo = (indirizzo) => {
        let parti = indirizzo.split(",")
        parti = parti.filter((el, index) => index <= parti.length - 5)
        return parti.toString()
    }

    // Funzione per scaricare il CSV
 const handleDownloadReports = () => {
    const csvContent = convertSegnalazioniToCSV(props.segnalazioni); // Converti i dati in CSV
    const url = window.URL.createObjectURL(new Blob([csvContent])); // Crea l'URL
    const link = document.createElement('a');
    const fileName = `Segnalazioni_${dayjs(new Date()).format("DDMMYYHHmm")}.csv`;
    link.href = url;
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    link.remove();
};

    return (
        <>
            <Row className="d-flex justify-content-between  my-4">
                <Col>
                    <Dropdown className="d-inline" autoClose="inside">
                        <Dropdown.Toggle id="dropdown-autoclose-outside">
                            <span className="px-5">Filtra</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <NavDropdown title="Stato" className="noChangeOnClick" drop="end" onSelect={filterByStato}>
                            {Object.values(statiSegnalazione)
                                    .map((stato, index) => (
                                        <NavDropdown.Item key={index} eventKey={stato}>{stato}</NavDropdown.Item>
                                    )
                                    )}
                            </NavDropdown>
                            <NavDropdown title="Categoria" className="noChangeOnClick" drop="end" onSelect={filterByCategoria}>
                                {
                                    props.comune && props.comune.impostazioniComune &&
                                    props.comune.impostazioniComune.imcCategorieSegnalazioni.categorie_segnalazioni.map((categoria, index) => (
                                        <NavDropdown.Item key={index} eventKey={categoria}>{categoria}</NavDropdown.Item>
                                    ))
                                }
                            </NavDropdown>
                        </Dropdown.Menu>
                    </Dropdown>
                    {
                        props.statoFilter.length > 0 &&
                        <Form.Label className="mx-4 mt-2 mt-md-0 d-block">
                            Stato: <strong> {props.statoFilter}</strong><Badge bg="outline-danger"><CloseButton onClick={resetStato} /></Badge>
                        </Form.Label>
                    }
                    {
                        props.categoriaFilter.length > 0 &&
                        <Form.Label className="mx-4  mt-2 mt-md-0 d-block">
                            Categoria: <strong> {props.categoriaFilter}</strong><Badge bg="outline-danger"><CloseButton onClick={resetCategoria} /></Badge>
                        </Form.Label>
                    }
                </Col>

                <Col xs={12} md={5} className="d-flex justify-content-end">
                    <InputGroup className="mt-2 mt-md-0" style={{maxHeight:"1rem"}}>
                        <InputGroup.Text id="basic-addon1">
                            <GoSearch />
                        </InputGroup.Text>
                        <FormControl placeholder="Cerca titolo..."
                            value={oggetto} onChange={(ev) => { setOggetto(ev.target.value) }}
                        />
                    </InputGroup>
                </Col>
                <Col className="text-end">  
                        <Button onClick={handleDownloadReports} variant="primary" >
                            <PiFileCsv className="me-1" />
                            Esporta tabella in formato csv
                        </Button>
                    </Col>
            </Row>

            <Table striped bordered hover responsive>
                <thead>
                    <tr>
                        <th>Titolo</th>
                        <th>Autore</th>
                        <th>Indirizzo</th>
                        <th className="text-center">Categoria</th>
                        <th className="text-center">Stato</th>
                        <th className="text-center nowrap">Inviata il</th>
                        <th className="text-center nowrap">Scadenza</th>
                        <th className="text-center">Azioni</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getSegnalazioni().length > 0 ? (
                            getSegnalazioni()
                                .sort((a, b) => { return getStatusOrderSegnalazione(a.segStato) - getStatusOrderSegnalazione(b.segStato) })
                                .map(segnalazione => (
                                    <tr key={segnalazione.segId}>
                                        <td>{segnalazione.segOggetto}</td>
                                        <td>{segnalazione.partecipante.datiPartecipante.dparNome + ' ' + segnalazione.partecipante.datiPartecipante.dparCognome}</td>
                                        <td>{formattaIndirizzo(segnalazione.posizione.posNome)}</td>
                                        <td className="text-center">{segnalazione.segCategoria}</td>
                                        <td className="text-center">
                                            <Badge pill bg={getSegnalazioneColor(segnalazione)} className="pillText" style={{ minWidth: "90%" }}>
                                                {segnalazione.segStato}
                                            </Badge>
                                        </td>
                                        <td className="text-center">{dayjs(segnalazione.segDataInvio).format("DD/MM/YYYY")}</td>
                                        <td className={"text-center"}>{(segnalazione.segStato === statiSegnalazione.Rifiutata || segnalazione.segStato === statiSegnalazione.Chiusa) ? "---" : scadenzaSegnalazione(segnalazione)}</td>
                                        <td>
                                        <AzioniTabella
                                                segId={segnalazione.segId}
                                                setSegId={props.setSegId}
                                                stato={segnalazione.segStato}
                                                setMostraTabella={props.setMostraTabella}
                                                setGestisci={props.setGestisci}
                                            />
                                        </td>
                                    </tr>
                                ))
                        ) : (
                            <tr>
                                <td colSpan="7">
                                    Nessuna segnalazione presente
                                </td>
                            </tr>
                        )
                    }
                </tbody>
            </Table>
        </>
    )

}